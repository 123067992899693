const baseClassName = 'letter-counter'
const cls = (name: string) => `${baseClassName}__${name}`
const warningClass = cls('warning')

const applyLetterCounter = (input: HTMLInputElement) => {
  const targetId = input.dataset.target
  if (!targetId) {
    return
  }

  const target_element = document.getElementById(targetId)
  if (!target_element) return

  const count_element = target_element.querySelectorAll('.' + cls('count'))[0]

  if (!input.dataset.max) {
    return
  }

  const max = parseInt(input.dataset.max)

  input.onkeyup = (event: Event) => {
    const current_count = input.value.length
    count_element.innerHTML = current_count.toString()

    const hasWarning = target_element.classList.contains(warningClass)

    if (current_count > max && !hasWarning) {
      target_element.classList.add(warningClass)
    } else if (current_count <= max && hasWarning) {
      target_element.classList.remove(warningClass)
    }
  }
}

export default function initLetterCounter() {
  document.querySelectorAll(`.${cls('input')}`).forEach((e) => applyLetterCounter(e as HTMLInputElement));
}
