// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// We need to import 'preact/debug' so that Stipe works in dev mode
// import 'preact/debug'
// Must be the first import
// if (process.env.NODE_ENV === 'development') {
//   // Must use require here as import statements are only allowed
//   // to exist at the top of a file.
//   require("preact/debug")
// }

// Turbo need to be loaded before @rails/ujs
import 'turbo_with_prefetch'

require("@rails/ujs").start()
// import Rails from "@rails/ujs"
// Rails.start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import 'on_document_ready'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// CSS
import '../../assets/stylesheets/fonts/icomoon/style.css'
import 'styles/styles.scss'

// Commented out ad we don't need react for now
// Support component names relative to this directory:
// @ts-ignore
// var componentRequireContext = require.context("components", true)
// var ReactRailsUJS = require("react_ujs")
// ReactRailsUJS.useContext(componentRequireContext)
