// add ".select_show_hide" class to a select box + data-target: '#some_id'
// on some of the options set data-shows: 'yes'

const applySelectShows = (select: HTMLElement) => {
  select.onchange = (event: Event) => {
    const select_box = event.target as HTMLSelectElement
    const selected = select_box.options[select_box.selectedIndex]
    const targetClass = select_box.dataset.target
    if (!targetClass) {
      return
    }

    const show_hide_target = document.querySelectorAll(targetClass)[0] as HTMLElement

    if (show_hide_target) {
      if (selected.dataset.shows) {
         show_hide_target.classList.remove('hide')
      } else {
         show_hide_target.classList.add('hide')
      }
    } else {
      // console.log(`Cannot find element: ${select_box.dataset.target}`)
    }
  }
}

export default function initSelectShows() {
  document.querySelectorAll('.select_shows').forEach((e) => applySelectShows(e as HTMLElement));
}
