import initBulmaUX from './bulma_ux'
import initSelectShows from './select_shows'
// to be deleted: 2021-08-03
// import initPrefetchOnHoverintent from './prefetch_on_hoverintent'
import initLetterCounter from './letter_counter'
// import initTrackEvents from './track_events'
// import initTribuneSupport from './tribune_support'

const onDocumentReady = () => {
  // @ts-ignore
  // gtag('config', 'UA-10949806-12', { 'page_path': window.location.pathname, 'anonymize_ip': true })
  initBulmaUX()
  // to be deleted: 2021-08-03
  // initPrefetchOnHoverintent()
  initSelectShows()
  initLetterCounter()
  // initTrackEvents()
  // initTribuneSupport()
}

// document.ready initialization
// enabling this runs onDocumentReady 2 times
// if (document.readyState != 'loading') { onDocumentReady(); }
// document.addEventListener('turbo:load', onDocumentReady)
document.addEventListener('turbo:render', onDocumentReady)
document.addEventListener('turbo:load', onDocumentReady)
